import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Icon } from '@vds/icons';
import { Input } from '@vds/inputs';

import './passwordValidator.scss';
import { Body } from '@vds/typography';

const PasswordValidatorComponent = ({
  data,
  label,
  readOnly,
  required,
  disabled,
  tooltip,
  rules,
  childToParent,
}) => {
  const [strength, setStrength] = useState(false);
  const [rulesets, setRules] = useState(rules);
  const [value, setValue] = useState('');
  const [touched, setTouched] = useState(false);
  const [pwdStrength, setPwdStrength] = useState('Weak');
  const [notValid, setValidValue] = useState(false);
  const updateLang = useSelector((state) => state.Aem.spanishLang);
  const engData = useSelector((state) => state.Aem.registerData);
  const isSpanish = useSelector((state) => state.Aem.isSpanish);
  const aemData = !isSpanish ? engData : updateLang;
  useEffect(() => {
    let valid = true;
    const newRule = rulesets.map((rule) => {
      rule.matched = value?.match(rule.regx);
      if (!rule.matched) valid = false;
      return { ...rule };
    });
    setRules(newRule);
    setStrength(valid && data !== value);
    childToParent(value, strength);
    setValidValue(data !== value ? false : true);
    setPwdStrength(
      value.length < 8 && strength
        ? aemData?.registerModule?.passwordValidator?.pwdWeakTxt :
        value.length >= 8 && value.length <= 10 && strength
          ? aemData?.registerModule?.passwordValidator?.pwdMediumTxt
          : (!valid) ? aemData?.registerModule?.passwordValidator?.pwdWeakTxt :
            (data === value) ? aemData?.registerModule?.passwordValidator?.pwdWeakTxt : aemData?.registerModule?.passwordValidator?.pwdStrongTxt
    );
  }, [value, strength]);

  return (
    <div className="validator-wrapper">
      <Input
        type={'password'}
        label={label}
        showPasswordButtonLabel={aemData?.registerModule?.passwordToolTip?.passShowBtn}
        hidePasswordButtonLabel={aemData?.registerModule?.passwordToolTip?.passHideBtn}
        readOnly={readOnly}
        required={required}
        disabled={disabled}
        error={value !== '' ? !strength : false}
        success={value !== '' ? strength : false}
        errorText="ss"
        successText="ee"
        tooltip={tooltip}
        maxLength={20}
        onChange={(e, v) => {
          setValue(e.nativeEvent?.target?.value);
          setTouched(true);
        }}
      />
      {touched && (
        <div>
          {`${aemData?.registerModule?.passwordValidator?.pwdStrength} : `}
          <span tabIndex={0} aria-label={aemData?.registerModule?.passwordValidator?.pwdStrength + pwdStrength}
            className={
              pwdStrength === aemData?.registerModule?.passwordValidator?.pwdStrongTxt
                ? 'success'
                : pwdStrength === aemData?.registerModule?.passwordValidator?.pwdMediumTxt
                  ? 'medium'
                  : 'error'
            }
          >
          {pwdStrength}
          </span>
        </div>
      )}

      <div className="mt-5X">
        {touched &&
          rulesets.map((rule) => {
            return (
              <>
                <div className='validation-points' key={rule.regx}>
                  <Icon
                    name={rule.matched ? 'checkmark' : 'error'}
                    size="medium"
                    color={rule.matched ? '#008330' : '#EE0000'}
                  />
                  <span className="ml-2X">
                    <Body size='small' color='#6f7171' primitive='span'>
                      {rule.ruleStatement}

                    </Body>

                  </span>
                </div>
              </>
            );
          })}
        {touched ? (
          <>
            <div className='validation-points'>
              <Icon
                name={value === data ? 'error' : 'checkmark'}
                size="medium"
                color={value === data ? '#EE0000' : '#008330'}
              />
              <span className="ml-2X">
                <Body size='small' color='#6f7171' primitive='span'>
                  {aemData?.registerModule?.passwordToolTip?.errorTxt}</Body></span>
            </div>
            {isSpanish ? <div className='validation-points'>
              {' '}
              <span style={{ marginLeft: '27px' }} >
                <Body size='small' color='#6f7171' primitive='span'>
                  {aemData?.registerModule?.passwordToolTip?.errorTxt1}</Body>
              </span>
            </div> : <></>}
            <div className='validation-points'>
              <Icon name={'minus'} size="medium" color={'#808080'} />
              <span className="ml-2X">
                <Body size='small' color='#6f7171' primitive='span'>
                  {aemData?.registerModule?.passwordValidator?.pwdTiptxt2}{' '}</Body>
              </span>
            </div>
            <div className='validation-points'>
              {' '}
              <span style={{ marginLeft: '27px' }}>
                <Body size='small' color='#6f7171' primitive='span'>
                  {aemData?.registerModule?.passwordValidator?.pwdTiptxt3}</Body>
              </span>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PasswordValidatorComponent;
