import React from 'react';
import { TileletGroup } from '@vds/tiles';

import "./MultiGrantsNavigation.scss";
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TextLink } from '@vds/buttons';
import { useTranslation } from 'react-i18next';

const MultiGrantsComponent = () => {
  const aemData = useSelector((state) => state.Aem.data);
  const dateJsonData = useSelector((state) => state.Aem.dateJson)
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <div className='multi-grants-component pb-16X sm-mh-4X'>
      <TileletGroup
        data={[
          {
            onClick: () => { 
              const grantsId = document.getElementsByClassName(`grant-status`)[1];
              window.scrollTo({
                top: grantsId?.offsetTop,
                behavior: "smooth"
              });
              document.getElementById('grantRegButton')?.focus();
             },
            textWidth: "100%",
            background: "#F3EDE0",
            backgroundColor: "white",
            showBorder: false,
            title: {
              size: 'titleLarge',
              children: aemData?.grants?.grantPromotionText,
            },
            subtitle: {
              size: 'bodyMedium',
              children: <div className='card-body-auto'>{aemData?.grants?.grantPromotionSubText}</div>,
              primitive: 'h1',
            },
            descriptiveIcon:{name:'down-arrow'},
            // directionalIcon: {
            //   name: 'down-arrow',
            //   size: 'medium',
            // },
          },
          {
            onClick: () => { navigate('/super-bowl') },
            background: "#F3EDE0",
            backgroundColor: 'white',

            title: {
              size: 'titleLarge',
              children: aemData?.grants?.superbowlPromotionText,
            },
            subtitle: {
              size: 'bodyMedium',
              children: <div className='card-body-auto'>{dateJsonData?.grants?.superbowlPromotionSubText}<br/><br/><TextLink ariaLabel={t('LegalDisclaimerAccessibilityText')} onClick={()=>{navigate('/super-bowl?scroll_to=promotion_details')}} size="small"  type="standAlone">{t('Legal disclaimer')}</TextLink></div>,
              primitive: 'p',
            },
            descriptiveIcon:{name:'right-arrow'},

            // directionalIcon: {
            //   size: 'medium'
            // },
            textWidth: "100%"
          }
        ]}
        rowQuantity={isMobile ? 1 : 2}
        //   gutterWidth="40px"
        //   width="490px"
        height={isMobile ? 'auto' : "310px"}
      //   aspectRatio="1:1" 
      />
    </div>
  );
};

export default MultiGrantsComponent;
