import { useEffect, useState } from 'react';

import { Input } from '@vds/inputs';

const EmailInputComponent = ({
  label,
  readOnly,
  disabled,
  required,
  successText,
  errorText,
  childToParent,
  existingEmail,
  message,
  captchaError,
  emailErr,
}) => {
  const [email, setEmail] = useState(existingEmail!=''||'undefined'?existingEmail:'');
  const [emailError, setEmailError] = useState(null);
  const [isBlurred, setIsBlurred] = useState(false);

  const handleBlur = () => {
    setIsBlurred(true);
  }
  useEffect(() => {
    if(isBlurred) {
      
    const regx = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    setEmailError(localStorage.getItem('email')===email?true:(message===captchaError)?false:regx.test(String(email).toLowerCase()) ? false : true);
    childToParent(email, !emailError);
    }
  }, [isBlurred]);

  useEffect(() => {
    const regx = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    setEmailError(localStorage.getItem('email')===email?true:(message===captchaError)?false:regx.test(String(email).toLowerCase()) ? false : true);

    childToParent(email, !emailError);
  }, [email, emailError]);

  return (
    <div>
      <Input
        type="text"
        label={label}
        readOnly={readOnly}
        required={required}
        disabled={disabled}
        maxLength={64}
        error={email !== '' ? emailError : false}
        success={message===captchaError?false:email !== '' ? !emailError : false}
        successText={successText}
        errorText={localStorage.getItem('email')===email?emailErr:errorText}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        onBlur={(e) => {
            handleBlur();
            setEmail(e.target.value);
          }}
        value={email}
      />
    </div>
  );
};

export default EmailInputComponent;
