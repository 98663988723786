import { suggestions } from "./suggestions";
import { getWithExpiry, setWithExpiry } from "./utils";

const retrieveJsonData = async (fileName) => {
  try {
    console.log("global.config");
    console.log(global.config);
    // Checking if localstore is having valid data of Json file
    if(getWithExpiry(fileName) != null) {
      return getWithExpiry(fileName);
    } else {
      let response = await fetch(global.config.AEM_URL + fileName);
      console.log('response---->', response);
      let responseJson = await response.json();
      setWithExpiry(fileName, responseJson, 10800000, new Date().getTime() + 10800000);
      return responseJson;
    }
  } catch (error) {
    console.error(error);
    // Adding Json data in local store for file having error with expiry as 2 minutes 
    setWithExpiry(fileName, null, 120000, new Date().getTime() + 120000);
    return null;
  }
};

const retrieveFileData = async (fileName) => {
  try {
    console.log("global.config");
    console.log(global.config);
    let response = await fetch(fileName);
    console.log('response---->', response);
    let responseJson = await response.text();
    let responseForFile = {payload: responseJson, fileName: fileName};
    return responseForFile;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const retrieveAdobeData = async () => {
  try {
    const adobeLMSURL = '/v2/user/adobelms/newSuggestions';
    let response = await fetch(global.config.PLATFORM_API_URL + adobeLMSURL);
    let responseJson = await response.json();
    console.log('responseJson---->', responseJson);
    return responseJson;
  } catch (error) {
    console.error(error);
    return null;
  }
};
const retrieveIncentive= async (language) => {
  const ms = Date.now();
  try {
    console.log("global.config");
    console.log(global.config);
    let response = await fetch(global.config.AEM_URL + "static-contents/incentives/incentives_"+language+".json?t="+ms);
    console.log('response---->', response);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const retrieveNetworking= async (language) => {
  const ms = Date.now();
  try {
    console.log("global.config");
    console.log(global.config);
    let response = await fetch(global.config.AEM_URL + "static-contents/networking/networking_"+language+".json?t="+ms);
    console.log('response---->', response);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
    return null;
  }
};
const getRecommendedCourses= async()=> {
  try {
    console.log("global.config");
    console.log(global.config);
    let response = await fetch(global.config.AEM_URL + "static-contents/dashboardjson/recommendedCourses.json")
    console.log('response---->', response);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const getQuickTipForcedRanking = async(language) => {
  const current = Date.now();
  let url = global.config.AEM_URL + "static-contents/dashboardjson/forcedRanking.json?t="+current;
  switch(language){
    case "en":
      url = global.config.AEM_URL + "static-contents/dashboardjson/forcedRanking.json?t="+current;
      break;
    case "es":
      url = global.config.AEM_URL + "static-contents/dashboardjson/forcedRankingSpanish.json?t="+current;
      break;
  }
   try {
    let response = await fetch(url)
    console.log('response---->', response);
    let responseJson = await response.json();
    return responseJson;
   } catch (error) {
     console.log(error);
     return null;
   }
}

const retrieveLearningPathDetailFromAEM= async (language) => {
  const ms = Date.now();
  try {
    console.log("global.config");
    console.log(global.config);
    let path = "learningPath";
      switch (language) {
        case "es":
          path = "learningPath_es";
          break;
      }
    let response = await fetch(global.config.AEM_URL + `static-contents/dashboardjson/${path}.json?t=`+ms);
    console.log('response---->', response);
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
    return null;
  }
};

// API for the simple search in category/courses 
const getLMSSearchResult = async (resouceType, searchText="")=>{
  let url
  console.log('global.config lms',global.config.PLATFORM_API_URL);
  const lng = localStorage.getItem("lng");
  switch(resouceType) {
    case "Course":
      url = `/v2/user/search?query=${searchText}&loTypes=course&sort=relevance&loFormat=Self Paced&fetchAll=true&language=es-ES,en-US`
      break;
    case "Event":
      url = `/v2/user/search?query=${searchText}&loTypes=course&sort=relevance&loFormat=Self Paced&cursor=${nextCursor}&tagName=${tags}&skillLevel=${skillLevel}&language=es-ES,en-US`
      break;
  }
  
  try{
    let response = await fetch(global.config.PLATFORM_API_URL + url);
    console.log('getlmsSearch response', response);
    let finalresponse = await response.json();
    return finalresponse
  }catch(error){
    console.log(error);
    return null
  }
}


export { retrieveJsonData, retrieveAdobeData, retrieveFileData,retrieveIncentive, retrieveNetworking,getRecommendedCourses, getQuickTipForcedRanking, retrieveLearningPathDetailFromAEM, getLMSSearchResult};
