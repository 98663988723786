/** @format */
import { createSlice } from '@reduxjs/toolkit';

export const aem = createSlice({
  name: 'aem',
  initialState: {
    data: null,
    registerData: [],
    partnerRegisterData: [],
    spanishLang: [],
    partnerSpanishLang: [],
    FAQ: [],
    superbowlFAQ: [],
    offerData: [],
    quickTips: null,
    incentives: [],
    articleContent: [],
    selectedCourse: '',
    isSpanish: false,
    password: '',
    registrationStepsData: {
      token: '',
      referralCode : '',
      user: {        
        email: '',
        password: ''
      },
      businessData: {
        topics: [],
        groups: [],
        city: '',
        state: '',
        zipcode: '',
        uiLocale: 'en-US',
        contentLocale: 'en-US',
        businessName: '',
      },
    },
    registerTriggeredFrom: "", //Used for holding value of the page in case user goes from register to sign in
    dateJson:""
  },

  reducers: {
    updateAEMJsonData: (state, action) => {
      state.data = action.payload;
    },
    updateAEMOffersJsonData: (state, action) => {
      state.offerData = action.payload;
    },
    updateAEMQuickTIpsData: (state, action) => {
      state.quickTipData = action.payload;
    },
    updateAEMRegisterData: (state, action) => {
      state.registerData = action.payload;
    },
    updateAEMPartnerRegisterData: (state, action) => {
      state.partnerRegisterData = action.payload;
    },
    updateLangSpanish: (state, action) => {
      state.spanishLang = action.payload;
    },
    updateLangForPartnerSpanish: (state, action) => {
      state.partnerSpanishLang = action.payload;
    },
    addFAQ: (state, action) => {
      state.FAQ = action.payload;
    },
    addSuperbowlFAQ: (state, action) => {
      state.superbowlFAQ = action.payload;
    },
    courseCx: (state, action) => {
      state.courseCx = action.payload;
    },
    updateIncentives: (state, action) => {
      state.incentives = action.payload;
    },
    updateArticleContent: (state, action) => {
      state.articleContent.push(action.payload);
    },
    updateSpanish: (state, action) => {
      state.isSpanish = action.payload;
    },
    updateSelectedCourse: (state, action) => {
      state.selectedCourse = action.payload;
    },
    registerPassword: (state, action) => {
      state.password = action.payload;
    },
    updateRegistrationStepsData: (state, action) => {
      state.registrationStepsData = action.payload;
    },
    updateRegistrationStepTopics: (state, action) => {
      state.registrationStepsData.businessData.topics = action.payload;
    },
    updateRegistrationStepGroups: (state, action) => {
      state.registrationStepsData.businessData.groups = action.payload;
    },
    updateRegistrationStepLocation: (state, action) => {
      state.registrationStepsData.businessData.city = action.payload.city;
      state.registrationStepsData.businessData.state = action.payload.state;
      state.registrationStepsData.businessData.zipcode = action.payload.zipcode;
    },
    updateAccessId: (state,action) => {
      state.registrationStepsData.businessData.partnerAccessId = action.payload
    },
    updateRegistrationStepUserDetails: (state, action) => {      
      state.registrationStepsData.user.email = action.payload.email;
      state.registrationStepsData.user.password = action.payload.password;
    },
    updateRegistrationStepCaptchaToken: (state, action) => {
      state.registrationStepsData.token = action.payload;
    },
    updateReferralCode: (state,action) => {
      state.registrationStepsData.referralCode = action.payload;
    },
    updateUiLocaledata:(state,action) => {
      state.registrationStepsData.businessData.uiLocale = action.payload.uiLocale;
      state.registrationStepsData.businessData.contentLocale = action.payload.contentLocale;
    },
    updateBusinessName: (state, action) => {
      state.registrationStepsData.businessData.businessName = action.payload.businessName;
    },
    updateRegisterTriggeredFrom: (state, action) => {
      state.registerTriggeredFrom = action.payload;
    },
    updateDateJson: (state, action) => {
      state.dateJson = action.payload;
    }
  },
});

export const {
  updateAEMJsonData,
  updateAEMOffersJsonData,
  updateAEMQuickTIpsData,
  updateAEMRegisterData,
  updateAEMPartnerRegisterData,
  updateLangSpanish,
  updateLangForPartnerSpanish,
  addFAQ,
  addSuperbowlFAQ,
  courseCx,
  updateIncentives,
  updateArticleContent,
  updateSelectedCourse,
  updateSpanish,
  registerPassword,
  updateRegistrationStepsData,
  updateRegistrationStepTopics,
  updateRegistrationStepGroups,
  updateRegistrationStepLocation,
  updateRegistrationStepUserDetails,
  updateRegistrationStepCaptchaToken,
  updateReferralCode,
  updateUiLocaledata,
  updateBusinessName,
  updateRegisterTriggeredFrom,
  updateAccessId,
  updateDateJson
} = aem.actions;

export default aem.reducer;
